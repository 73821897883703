<!-- 游戏记录 -->
<template>
    <div class="viewsPages">
        <div class="page-gamerecord">
            <!-- <div class="title">{{$t("userPage.text125")}}</div> -->
            <!-- <div class="line"></div> -->
            <div class="remark">{{$t("userPage.text126")}}</div>
            <!-- 游戏平台-投注金额 -->
            <div class="input-box">
                <div class="text">{{$t("userPage.text127")+"："}}</div>
                <Select v-model="form.platform">
                    <Option v-for="(item, index) in recordAllPlatform"  :key="index" :label="item.vendorName" :value="item.vendorCode"></Option>
                </Select>
                <div class="text">{{$t("userPage.text128")+"："}}</div>
                <div class="input">
                    <Input v-model="form.start" placeholder="0" />
                    <span class="split-line">—</span>
                    <Input v-model="form.end" placeholder="0" />
                </div>
            </div>
            <!-- 时间筛选 -->
            <div class="input-box">
                <div class="text">{{$t("userPage.text129")+"："}}</div>
                <div class="date-box">
                    <DatePicker v-model="form.startTime" :valueplaceholder='$t("userPage.text5")' />
                    <span class="split-line" >—</span>
                    <DatePicker v-model="form.endTime" :placeholder='$t("userPage.text5")' />
                </div>
                <div class="buttom-box">
                    <div class="query-buttom"  @click="query">{{$t("userPage.text130")}}</div>
                    <div class="reset-buttom" @click="reset">{{$t("userPage.text131")}}</div>
                </div>
            </div>
            <div class="content-box" v-if="recordGameList.length > 0">
                <!-- 表格 -->
                <Table :data="recordGameList">
                    <!-- 订单号 -->
                    <TableColumn prop="orderNo" :label='$t("userPage.text132")' width="100px" align="center">
                        <template slot-scope="scope">
                            <Tooltip :content="scope.row.betNo" placement="top-start">
                                <span>{{ scope.row.betNo }}</span>
                            </Tooltip>
                        </template>
                    </TableColumn>
                    <!-- 游戏平台 -->
                    <TableColumn prop="vendorName" :label='$t("userPage.text133")' align="center"></TableColumn>
                    <!-- 游戏名称 -->
                    <TableColumn prop="gameName" :label='$t("userPage.text134")' align="center"></TableColumn>
                    <!-- 投注时间 -->
                    <TableColumn prop="_createdAt" :label='$t("userPage.text135")' align="center"></TableColumn>
                    <!-- 派彩时间 -->
                    <TableColumn prop="_payoutTime" :label='$t("userPage.text136")' align="center"></TableColumn>
                    <!-- 投注金额 -->
                    <TableColumn prop="_betAmount" :label='$t("userPage.text137")' align="center"></TableColumn>
                    <!-- 有效投注 -->
                    <TableColumn prop="_betAmountValid" :label='$t("userPage.text138")' align="center"></TableColumn>
                    <!-- 派彩金额 -->
                    <TableColumn prop="_payoff" :label='$t("userPage.text139")' align="center"></TableColumn>
                    <!-- 盈亏金额 -->
                    <TableColumn prop="winLoss" :label='$t("userPage.text140")' align="center"></TableColumn>
                </Table>

                <!-- 统计 -->
                <div class="total-box">
                    <div class="total-box-top">
                        <div>{{$t("userPage.text141")+"："}}<span class="total-value">￥{{ recordGameCount.totalBet }}</span></div>
                        <div>{{$t("userPage.text142")+"："}} <span class="total-value">￥{{ recordGameCount.effective }}</span></div>
                    </div>
                    <div class="total-box-bottom">
                        <div>{{$t("userPage.text143")+"："}} <span class="total-value">￥{{ recordGameCount.distributed }}</span></div>
                        <div>{{$t("userPage.text144")+"："}} <span class="total-value">￥{{ recordGameCount.profit }}</span></div>
                    </div>
                </div>
                <div class="pagination-boxs">
                    <!-- 分页 -->
                    <Pagination
                        layout="prev, pager, next"
                        :hide-on-single-page="true"
                        :current-page.sync="form.currPage"
                        :page-size="form.pageSize"
                        :total="recordGameTotal"
                        @size-change="getData"
                        @current-change="getData"
                        ></Pagination>
                </div>
            </div>
            <div v-else class="null-box">
                <img loading="lazy" v-lazy="require('../../../assets/images/my/null.png')">
                <div>{{$t("userPage.text12")}} </div>
            </div>
        </div>
    </div>  
</template>

<script>
import { _allPlatform } from '../../../core/api/modules/records'
import { _gameRecordsAction } from '../../../core/comp/pages/records'
import { Select, Option, Input, DatePicker, Pagination, Table, TableColumn, Tooltip } from 'element-ui'
import { mapGetters } from 'vuex'

export default {
    components: {
        Select,
        Option,
        Input,
        DatePicker,
        Pagination,
        Table,
        TableColumn,
        Tooltip,
    },
    data () {
        return {
            form: { // 表单
                platform: '',
                start: '',
                end: '',
                startTime: '',
                endTime: '',
                currPage: 1,
                pageSize: 5,
            }
        }
    },
    computed: {
        ...mapGetters([
            'recordAllPlatform', // 所有游戏平台
            'recordGameList', // 表格数据
            'recordGameTotal', // 总条数
            'recordGameCount', // 数据统计
        ])
    },
    created () {
        _allPlatform()
        this.getData()
    },
    methods: {
        
        // 查询
        query () {
            this.form.currPage = 1
            setTimeout(() => {
                this.getData()
            }, 0)
        },
        // 获取数据
        getData () {
            console.log('获取数据')
            _gameRecordsAction(this.form)
        },
        // 重置表单
        reset () {
            this.form = { // 表单
                platform: '',
                start: '',
                end: '',
                startTime: '',
                endTime: '',
                currPage: 1,
                pageSize: 5,
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/page-game-record.scss";
</style>

<style lang="scss">
.page-gamerecord{
    .content-box{
        .el-table{
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background:transparent;
            box-shadow: 0px 3px 10px 0 #c7e7f7;
            &::before{
                display:none;
            }
            .el-table__header-wrapper{
                .el-table__header{
                    .has-gutter{
                        tr{
                            color: #000;
                            background: linear-gradient(180deg, #fffdc4, #e3bd29, #916801);
                        }
                        th.el-table__cell.is-leaf{
                            background:transparent;
                            border-bottom:1px solid #E3E3E3;
                        }
                    }
                }
            }
            .el-table__body-wrapper{
                .el-table__body{
                    .el-table__row{
                        background:#fff;
                        &:hover{
                            td.el-table__cell{
                                background:transparent;
                            }
                        }
                        td{
                            color: #323232;
                            border-bottom:1px solid #E3E3E3;
                        }
                    }
                    
                    tr.el-table__row:nth-last-child(1) td{
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
</style>